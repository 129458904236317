<template>
  <div class="flex flex-col items-center">
    <div class="max-w-5xl w-full">

      <h2 class="text-2xl font-medium p-4 border-b-2 border-red-600">
        Board of Directors’ Remuneration Advisory
      </h2>

      <img
        src="../../assets/img/directors_remuneration_banner.jpg"
        alt="Board of Directors’ Remuneration Advisory Banner"
        class="object-cover w-full lg:h-96 h-80 mt-4"
      >

      <div class="p-4">
        <p class="py-4">
          Powered by <b>MRC Board of Directors’ Remuneration</b>, MRC is a trusted partner
          for public-listed firms’ Remuneration Committees (“RC”) for objective assessment
          and recommendation of directors’ remuneration, including that of executive directors,
          non-executive directors, independent non-executive directors and
          senior management, as regards:
        </p>
        <ul class="px-4 pb-4 list-disc text-red-700">
          <li>Cash payment (base salary, allowances & short-term Incentives)</li>
          <li>Equity compensation (awards of stock, options and the related)</li>
          <li>Long-term cash (deferred cash compensation)</li>
          <li>Benefits and perquisites (medical, car, housing, etc.)</li>
          <li>Directors’ fees</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>

<script>
export default {};
</script>
